@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'






















































































































.v-list-item.dense
  font-size: 0.75rem !important
  font-weight: 400
  line-height: 1.25rem
  letter-spacing: normal !important
  font-family: "Roboto", sans-serif !important
  min-height: 20px

  .v-list-item__icon
    margin: 0 9px 0 0

  .v-list-item__content
    padding: 0
