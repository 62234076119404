@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'


































































































































































































































































































































.v-data-table::v-deep
  .v-data-table__wrapper
    table
      tbody > tr:hover
        cursor: pointer

      tbody > tr > td, thead > tr > th
        padding-right: 0 !important
        padding-left: 10px !important

        &:last-child, &.v-data-table__divider
          padding-right: 10px !important

        &.v-data-table__mobile-row:last-child
          padding-right: 0 !important
