@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'


























































































.v-btn-toggle
  @media #{map-get($display-breakpoints, 'xs-only')}
    min-width: 100%

  .v-btn.v-btn--block
    min-width: 48px !important
